var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"mine-finished-product-task"},[_c('el-card',{staticClass:"list-page-card",attrs:{"shadow":"never"}},[_c('os-table-query',{attrs:{"queryForm":_vm.queryForm,"queryItemsOption":_vm.queryItemsOption},on:{"query-click":_vm.reloadData}})],1),_c('el-card',{staticClass:"list-page-card",attrs:{"shadow":"never"}},[_c('os-query-plan',{ref:"OsQueryPlan",attrs:{"code":_vm.code,"json":JSON.stringify(_vm.queryForm),"queryEndPlanOptions":_vm.queryPlanEndOption},on:{"query-list":_vm.queryList}})],1),_c('el-card',{staticClass:"list-page-card",attrs:{"shadow":"never"}},[_c('os-table-operation',{attrs:{"operationOptions":_vm.operationOptions,"customColumns":_vm.customColumnOptions},on:{"column-change":function($event){return _vm.handleColumnChanged(
          $event,
          'mine-finished-product-task',
          'mineFinishedProductTaskTable'
        )},"column-reset":function($event){return _vm.handleColumnReset(
          _vm.defaultColumnOptions,
          'mine-finished-product-task',
          'mineFinishedProductTaskTable'
        )}},scopedSlots:_vm._u([{key:"other-end",fn:function(){return [_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(_vm.$t("prepressTask.screenAreaTotal"))+" : "+_vm._s(_vm.area)+"㎡")])]},proxy:true}])}),_c('os-table',{ref:"mineFinishedProductTaskTable",attrs:{"tableOption":_vm.tableOption,"tableColumnOption":_vm.columnOptions},on:{"selection-change":_vm.handleSelectionChange,"sort-change":_vm.handleSortChange},scopedSlots:_vm._u([{key:"itemCode",fn:function(data){return [_c('span',{class:data.row.urgentOrderFlag === 1 ? 'urgent' : 'not-urgent'},[_c('svg-icon',{attrs:{"slot":"reference","name":"#urgent-order","aria-hidden":"true"},slot:"reference"})],1),_c('el-link',{staticClass:"el-link-mini",attrs:{"type":"primary"},on:{"click":function($event){return _vm.linkToDetails(data.row)}}},[_vm._v(_vm._s(data.row.itemCode))]),(data.row.modifyCount && data.row.modifyCount > 1)?_c('svg-icon',{staticStyle:{"margin-left":"10px","vertical-align":"middle"},attrs:{"slot":"reference","width":"16px","height":"16px","name":"#reddit","aria-hidden":"true"},slot:"reference"}):_vm._e()]}},{key:"thumbnails",fn:function(data){return [(data.row.thumbnails)?_c('el-link',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.thumbnailsPreview(data.row.thumbnails)}}},[_vm._v(_vm._s(_vm.$t("prepress.preview")))]):_c('span',[_vm._v(_vm._s(_vm.$t("prepress.noPreview")))]),(_vm.showThumbnails)?_c('el-image-viewer',{attrs:{"on-close":function () {
              _vm.showThumbnails = false;
            },"url-list":_vm.previewImgList}}):_vm._e()]}},{key:"prepressStatus",fn:function(data){return [_c('span',{class:_vm.getStatusClass(data.row)}),_vm._v(_vm._s(_vm.$t(_vm.getStatusName(data.row)))+" ")]}}])}),_c('os-pagination',{attrs:{"paging":_vm.paging,"total":_vm.totalData},on:{"pagination":_vm.pagingData}})],1),_c('prepress-task-details',{attrs:{"visible":_vm.detailsVisible,"currentCompleteItemType":_vm.currentCompleteItemType,"prepressId":_vm.prepressId || 0},on:{"update:visible":function($event){_vm.detailsVisible=$event}}}),_c('upload-file',{ref:"artworkConfirmation",attrs:{"acceptFileType":'.jpg,.jpeg,.png',"uploadHooks":{ beforeUpload: _vm.beforeUploadConfirm },"acceptLimit":30,"visible":_vm.artworkConfirmationVisible,"uploadTitle":_vm.$t('prepressTask.confirmUpload'),"upload-module":"artworkConfirmation"},on:{"update:visible":function($event){_vm.artworkConfirmationVisible=$event},"upload-success":_vm.artworkConfirmation}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }